import { css } from '@emotion/react';
import {
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Link,
  CircularProgress,
  Alert,
  IconButton,
  Popover,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { differenceInDays } from 'date-fns';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  InfoPopover,
  SlideLeftTransition,
  StatusChip,
} from 'src/components';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import {
  useAppRoutes,
  useGetAuthorizationOrTransaction,
  useGetCard,
  useGetUserPrivileges,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { formatDateToMST, searchParamsToObject } from 'src/utils';
import { STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS } from 'src/utils/consts';

// import { useCreateDisputeModal } from './CreateDisputeModal';
import { useDisputeDetailsModal } from './DisputeDetailsModal';

import type { TransactionAuthCombinedRaw } from 'src/types';

export const TRANSACTION_DETAILS_MODAL_ID = 'transaction-details-modal';

const dataRowLabelWidth = 120;

const useGetSelectedTransaction = () => {
  const [searchParams] = useSearchParams();
  const selectedTransactionId = searchParams.get('selectedTransactionId');
  const type = searchParams.get('type');
  return useGetAuthorizationOrTransaction({
    id: selectedTransactionId ?? '',
    type: (type as TransactionAuthCombinedRaw['entity_type']) ?? '',
  });
};

export const useTransactionDetailsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: TRANSACTION_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/transactions', {
        queryParams: omit(currentSearchParams, [
          'selectedTransactionId',
          'type',
        ]),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Transaction Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string, type: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/transactions');
      navigate('/secure/transactions', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          selectedTransactionId: id,
          type,
        },
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const TransactionTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { data: selectedTransaction } = useGetSelectedTransaction();

  return (
    <TrackingContextProvider
      trackingProps={{
        transactionId: selectedTransaction?.id,
        transactionStripeId: selectedTransaction?.stripeId,
        transactionType: selectedTransaction?.type,
        transactionStatus: selectedTransaction?.status,
        transactionAmountCents: selectedTransaction?.amountCents,
        cardId: selectedTransaction?.cardId,
        cardholderId: selectedTransaction?.cardholderId,
        transactionCreatedAt: selectedTransaction?.authorizationDate
          ? new Date(selectedTransaction.authorizationDate).toISOString()
          : null,
      }}
      contextName={TrackContext.transaction}
      {...props}
    />
  );
};

const TransactionDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { privileges } = useGetUserPrivileges();
  const { pathname, getRoutePathName, generatePath, navigate } = useAppRoutes();
  const { isOpen, openModal, closeModal } = useTransactionDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Transaction Details',
      });
    },
  });
  // const { openModal: openCreateDisputeModal } = useCreateDisputeModal();
  const { openModal: openDisputeModal } = useDisputeDetailsModal();
  const [searchParams] = useSearchParams();
  const selectedTransactionId = searchParams.get('selectedTransactionId');
  const selectedType = searchParams.get('type');
  useEffect(() => {
    if (!isOpen && selectedTransactionId && selectedType) {
      openModal(selectedTransactionId, selectedType);
    }
  }, [isOpen, openModal, selectedTransactionId, selectedType]);

  const isSamePage = pathname === getRoutePathName('/secure/transactions');
  useEffect(() => {
    if (isOpen && (!selectedTransactionId || !selectedType) && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedTransactionId, selectedType, isSamePage]);

  const { data: selectedTransaction, isLoading } = useGetSelectedTransaction();
  const {
    stripeId,
    amountCents,
    status,
    displayDate,
    paymentMethod,
    merchantName,
    type,
    cardId,

    cardholderId,
    ...rest
  } = selectedTransaction ?? {};

  // TODO: remove this seperate fetch of the card for the nickname once the BE returns the related card on the transaction fetch.
  const { data: card } = useGetCard({ id: cardId ?? '' });

  const cardholderLink = generatePath('/secure/cardholders', undefined, {
    selectedCardholderId: cardholderId ?? '',
  });
  const cardLink = generatePath('/secure/cards', undefined, {
    selectedCardId: cardId ?? '',
  });
  const isTransaction = type === 'transaction';
  const isDisputed = 'disputeId' in rest && !!rest.disputeId;

  const daysSinceTransaction = differenceInDays(
    new Date(),
    new Date(selectedTransaction?.createdAt || 0),
  );
  const isDisputable =
    isTransaction &&
    daysSinceTransaction <= STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS;
  const disputeActionsVisible = !isDisputed ? isDisputable : true;

  return (
    <Popover
      open={isOpen}
      onClose={closeModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Transaction Details
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading || !selectedTransaction ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              css={css`
                padding: 10px 8px;
              `}
            >
              <DataRow
                label="Status"
                value={
                  <Box
                    css={css`
                      display: flex;
                      gap: 2px;
                      flex-wrap: wrap;
                      align-items: center;
                    `}
                  >
                    <StatusChip status={status ?? ''} type="transaction" />
                    {status === 'declined' &&
                      'requestHistoryMessage' in selectedTransaction && (
                        <Box>
                          <Typography
                            variant="caption"
                            component="p"
                            css={css`
                              color: ${COLOR_PALETTE.lightTextOnLight};
                              font-size: 10px;
                              font-weight: 500;
                            `}
                          >
                            Decline Reason:
                          </Typography>
                          <Typography
                            variant="caption"
                            component="p"
                            css={css`
                              font-size: 12px;
                              font-weight: 400;
                            `}
                          >
                            {selectedTransaction.requestHistoryMessage}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                }
                css={css`
                  flex: 1 1 100%;
                `}
                labelWidth={dataRowLabelWidth}
              />
              <DataRow
                label={
                  <>
                    Date
                    <InfoPopover
                      contents="All times are displayed in Mountain Standard Time (MST)."
                      name="Date"
                      css={css`
                        margin-left: 2px;
                      `}
                    />
                  </>
                }
                value={
                  displayDate
                    ? formatDateToMST(
                        new Date(displayDate),
                        'MM/dd/yyyy h:mmaaa',
                      )
                    : ''
                }
                labelWidth={dataRowLabelWidth}
              />
              <DataRow
                label="Amount"
                value={<FancyCurrencyDisplay amountCents={amountCents} />}
                labelWidth={dataRowLabelWidth}
              />
              <DataRow
                label="Merchant"
                value={merchantName}
                labelWidth={dataRowLabelWidth}
              />
              <DataRow
                label="Payment Method"
                value={paymentMethod}
                isPrivate
                labelWidth={dataRowLabelWidth}
              />
              <DataRow
                label="Card"
                value={
                  privileges.cards ? (
                    <Typography
                      component="a"
                      href={cardLink}
                      css={css`
                        color: inherit;
                      `}
                    >
                      {selectedTransaction.cardLabel ?? ''}
                    </Typography>
                  ) : (
                    selectedTransaction.cardLabel
                  )
                }
                labelWidth={dataRowLabelWidth}
              />
              {privileges.cards && (
                <DataRow
                  label="Card Nickname"
                  value={card?.nickname}
                  labelWidth={dataRowLabelWidth}
                />
              )}
              <DataRow
                label="Cardholder"
                value={
                  privileges.cards ? (
                    <Typography
                      component="a"
                      href={cardholderLink}
                      css={css`
                        color: inherit;
                      `}
                    >
                      {selectedTransaction.cardholderFullName ?? ''}
                    </Typography>
                  ) : (
                    selectedTransaction.cardholderFullName
                  )
                }
                labelWidth={dataRowLabelWidth}
              />
            </Box>
            {!!privileges?.dispute && isTransaction && (
              <Box
                css={css`
                  padding: 32px 8px 10px;
                `}
              >
                {isDisputed ? (
                  <>
                    {!!privileges?.create_dispute && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openDisputeModal(rest?.disputeId ?? '')}
                      >
                        View Dispute
                      </Button>
                    )}
                  </>
                ) : disputeActionsVisible ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      track({
                        label: 'Clicked dispute button',
                        type: TrackType.action,
                        actionType: 'click',
                      });
                      navigate('/secure/disputes/new-dispute', {
                        queryParams: {
                          transactionId: stripeId ?? '',
                        },
                      });
                      // openCreateDisputeModal(stripeId ?? '');
                    }}
                  >
                    Dispute Transaction
                  </Button>
                ) : (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                      margin-bottom: 16px;
                    `}
                  >
                    The transaction is {daysSinceTransaction} days old. Disputes
                    can not be raised for transactions older than{' '}
                    {STRIPE_TIME_LIMIT_TO_RAISE_DISPUTE_IN_DAYS} days.
                  </Alert>
                )}
              </Box>
            )}
          </>
        )}
        <Typography
          variant="body1"
          css={css`
            margin-top: auto;
            width: 100%;
            padding: 16px;
            text-align: center;
            font-size: 12px;
            font-weight: 300;
            line-height: 166.66%;
            color: ${COLOR_PALETTE.lightTextOnLight};
          `}
        >
          If you have any issues with this transaction, please contact us at{' '}
          <Link
            href={`mailto:support@benjicard.com?subject=Issue with transaction (${stripeId})`}
            css={css`
              color: ${COLOR_PALETTE.blueLink};
            `}
          >
            support@benjicard.com
          </Link>
          .
        </Typography>
      </DialogContent>
    </Popover>
  );
};

export const TransactionDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <TransactionTrackingContextProvider>
      <TransactionDetailsModalBase anchorEl={anchorEl} />
    </TransactionTrackingContextProvider>
  );
};
