import { formatDateToMST } from 'src/utils';

import type {
  TransactionAuthCombinedCleaned,
  TransactionAuthCombinedRaw,
  TransactionCleaned,
  TransactionRaw,
} from 'src/types/authorizations-transactions';

export const cleanTransactionsAuthsCombined = (
  rawTransactions: TransactionAuthCombinedRaw[],
) =>
  rawTransactions.reduce((acc, transaction) => {
    const prevTransaction = acc[acc.length - 1];
    const prettyAuthDate = formatDateToMST(new Date(transaction.created));
    const prettyDisplayDate = formatDateToMST(
      new Date(transaction.display_date),
    );
    return [
      ...acc,
      {
        id: transaction.id,
        stripeId: transaction.stripe_authorization_id,
        cardId: transaction.card_id,
        cardholderId: transaction.cardholder_id,
        createdAt: transaction.created,
        type: transaction.entity_type,
        merchantName: transaction.merchant_name,
        amountCents: transaction.amount,
        status: transaction.status,
        paymentMethod: `****${transaction.last4} ${transaction.cardholder?.name}`,
        prettyAuthDate,
        prettyDisplayDate,
        displayDate: transaction.display_date,
        isDisplayDateSameAsPrev:
          prevTransaction?.prettyDisplayDate === prettyDisplayDate,
        group: transaction.cardholder?.group,
        cardNickname: transaction.card?.nickname || '',
      } as TransactionAuthCombinedCleaned,
    ];
  }, [] as TransactionAuthCombinedCleaned[]);

export const cleanTransactions = (rawTransactions: TransactionRaw[]) =>
  rawTransactions.reduce((acc, transaction) => {
    const prevTransaction = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(new Date(transaction.created));
    return [
      ...acc,
      {
        id: transaction.id,
        stripeId: transaction.stripe_transaction_id,
        cardId: transaction.card_id,
        cardholderId: transaction.cardholder_id,
        createdAt: transaction.created,
        authorizationDate: transaction.authorization_date,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevTransaction?.prettyCreatedAtDate === prettyCreatedAtDate,
        merchantName: transaction.merchant_data.name,
        amountCents: transaction.amount,
        cardLast4: transaction.card_last4,
        paymentMethod: `****${transaction.card_last4} ${transaction.cardholder_name}`,
      } as TransactionCleaned,
    ];
  }, [] as TransactionCleaned[]);
