import { css } from '@emotion/react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';

import { useAppRoutes, useGetAuthorizationOrTransaction } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { USDFormatter } from 'src/utils';

export const NewDisputePage = () => {
  const { navigate } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const transactionStripeId = searchParams.get('transactionId');

  const { data } = useGetAuthorizationOrTransaction({
    id: transactionStripeId ?? '',
    type: 'transaction',
  });
  const { prettyDisplayDate, amountCents } = data ?? {};

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 20px;
          flex-wrap: wrap-reverse;
          padding-bottom: 36px;
          border-bottom: 1px solid ${COLOR_PALETTE.border};
        `}
      >
        <Typography
          component="h1"
          css={css`
            font-size: 20px;
            font-weight: 700;
          `}
        >
          Create Dispute
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => navigate(-1)}
          css={css`
            background-color: ${COLOR_PALETTE.lightButtonColor};
          `}
        >
          <GridCloseIcon
            css={css`
              height: 20px;
              width: 20px;
            `}
          />
        </IconButton>
      </Box>
      <Box
        css={css`
          width: 100%;
          max-width: 680px;
          margin: 0 auto;
          padding: 16px 16px 24px;
        `}
      >
        <Typography
          css={css`
            text-align: center;
          `}
        >
          To dispute a transaction, please email us at{' '}
          <Link
            href={`mailto:support@benjicard.com?subject=New Dispute${
              transactionStripeId ? ` (${transactionStripeId})` : ''
            }&body=Hello,%0A%0AWe%20need%20to%20dispute%20this%20transaction:%0A%0ATransaction%20Date: ${
              prettyDisplayDate ? prettyDisplayDate : ''
            }%0ATransaction%20Amount: ${
              amountCents ? USDFormatter.format(amountCents / 100) : ''
            }%0ADispute%20Reason:%0A`}
            css={css`
              color: ${COLOR_PALETTE.blueLink};
            `}
          >
            support@benjicard.com
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
};
