import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type { SubUserDetailsCleaned, SubUserDetailsRaw } from 'src/types/user';

export const useGetSubUser = ({ id }: { id: string }) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getSubUser = async ({ id }: { id: string }) => {
    const { data: subUser } = await axios.get<SubUserDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.USERS_GET_SUB_USER_DETAILS, {
        subUserId: id,
      })}`,
      {
        headers: await generateSecureHeaders(),
      },
    );
    const prettyCreatedAtDate = formatDateToMST(new Date(subUser.created_at));
    const status = (subUser.status ?? '').toLowerCase();

    return {
      id: subUser.id,
      name: {
        first: subUser.first_name,
        last: subUser.last_name,
        full: [subUser.first_name, subUser.last_name].join(' '),
      },
      email: subUser.email,
      status,
      isActive: status === 'active',
      createdAt: subUser.created_at,
      prettyCreatedAtDate,
      privileges: omit(subUser.privileges, ['id', 'user_id']),
    } as SubUserDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.subUsers.details({
      id,
    }),
    queryFn: () =>
      getSubUser({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.transactions && !!id,
  });
};
