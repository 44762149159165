import { css } from '@emotion/react';
import { Box, Card, Typography } from '@mui/material';
import { get } from 'lodash';
import { useRef, useState } from 'react';

import {
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  Table,
  StatusChip,
  TableLoader,
  TableError,
  TableNoResults,
  TablePagination,
  TableMenu,
  InfoPopover,
} from 'src/components';
import { CreateDisputeModal } from 'src/components/modals/CreateDisputeModal';
import {
  TransactionDetailsModal,
  useTransactionDetailsModal,
} from 'src/components/modals/TransactionDetailsModal';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import { TrackType, useToast, useTrack } from 'src/context';
import { useGetFeatures, useGetUserPrivileges } from 'src/hooks';
import {
  DownloadEntity,
  useDownloadTableCsv,
} from 'src/hooks/useDownloadTableCsv';
import { Privilege } from 'src/types';
import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { cleanTransactionsAuthsCombined } from './utils';

import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { TableColumn } from 'src/components/Table';
import type {
  TransactionAuthCombinedCleaned,
  TransactionAuthCombinedRaw,
} from 'src/types';

const Transactions = () => {
  const { track } = useTrack();
  const { setToast } = useToast();
  const { features } = useGetFeatures();
  const { privileges } = useGetUserPrivileges();

  const cardRef = useRef<HTMLDivElement>(null);

  const { openModal: openTransactionModal } = useTransactionDetailsModal();

  const columns: ({
    width: string;
  } & TableColumn<TransactionAuthCombinedCleaned>)[] = [
    {
      label: 'Date',
      renderLabel: () => {
        return (
          <>
            Date
            <InfoPopover
              contents="All times are displayed in Mountain Standard Time (MST)."
              name="Date"
              css={css`
                margin-left: 2px;
              `}
            />
          </>
        );
      },
      renderCell: () => {
        return <span />;
      },
      width: '195px',
    },
    {
      label: 'Payment',
      renderCell: ({ paymentMethod }) => {
        return <span className="highlight-block">{paymentMethod}</span>;
      },
      width: 'minmax(200px, 300px)',
    },
    {
      label: 'Merchant',
      renderCell: ({ merchantName }) => {
        return merchantName;
      },
      width: 'minmax(150px, 250px)',
    },
    {
      label: 'Status',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="transaction" />
          </Box>
        );
      },
      width: '130px',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '140px',
    },
    ...(privileges.cards
      ? [
          {
            label: 'Card Nickname',
            renderCell: ({ cardNickname }: TransactionAuthCombinedCleaned) =>
              cardNickname,
            width: 'minmax(150px, 250px)',
          },
        ]
      : []),
    ...(features?.groups
      ? [
          {
            label: 'Group',
            renderCell: ({ group }: TransactionAuthCombinedCleaned) =>
              group?.name,
            width: 'minmax(150px, 250px)',
          },
        ]
      : []),
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const [filters, setFilters] = useState<PaginatedTableFilter>({});

  const { mutate: download, isLoading: isDownloading } = useDownloadTableCsv();

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 96px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<
            TransactionAuthCombinedRaw,
            TransactionAuthCombinedCleaned
          >
            endpointUrl={SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_ALL}
            queryKeyBase={queryKeys.transactions._baseKey}
            dataFormatter={cleanTransactionsAuthsCombined}
            requiredPrivileges={[Privilege.transactions]}
            filters={filters}
          >
            {({
              queryResult: { isLoading, isError },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => {
              return (
                <>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      gap: 8px 16px;
                      padding: 16px 16px 0;
                    `}
                  >
                    <Typography
                      variant="h2"
                      css={css`
                        flex-grow: 999;
                      `}
                    >
                      Transactions
                    </Typography>
                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap-reverse;
                        gap: 16px;
                        justify-content: flex-end;
                        flex-grow: 1;
                      `}
                    >
                      <SearchBar {...searchProps} isLoading={!!isLoading} />
                      <TableMenu
                        disabled={isLoading}
                        menuItems={[
                          {
                            label: 'Download CSV',

                            onClick: () => {
                              track({
                                label: 'Clicked Download Transactions Table',
                                type: TrackType.action,
                                actionType: 'click',
                              });
                              const today = new Date();
                              const displayDateStartDate = get(
                                filters,
                                'displayDate.startDate',
                              );
                              const dispalyDateEndDate = get(
                                filters,
                                'displayDate.endDate',
                              );
                              const hasOnlyStartDateSet =
                                !!displayDateStartDate && !dispalyDateEndDate;
                              download(
                                {
                                  downloadEntity: DownloadEntity.TRANSACTIONS,
                                  filters: {
                                    ...filters,
                                    ...(!!hasOnlyStartDateSet && {
                                      displayDate: {
                                        startDate: displayDateStartDate,
                                        // Default end date to today if only start date is set.
                                        endDate: formatDateToMST(
                                          today,
                                          'yyyy-MM-dd',
                                        ),
                                      },
                                    }),
                                    search: searchProps.searchValue,
                                  },
                                },
                                {
                                  onError: (error) => {
                                    const message = get(
                                      error,
                                      'message',
                                      'An error occurred while downloading the CSV. Try different filters or try again later.',
                                    );
                                    setToast({
                                      message,
                                      severity: 'error',
                                    });
                                  },
                                },
                              );
                            },
                            disabled: isDownloading,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      gap: 8px 16px;
                      flex-wrap: nowrap;
                      padding: 0 16px;
                    `}
                  >
                    <TableFilters
                      setFilters={setFilters}
                      priorityFilterCount={0}
                      filters={[
                        {
                          name: 'displayDate',
                          label: 'Date',
                          type: FilterType.DATE_RANGE,
                          defaultValue: {
                            startDate: null,
                            endDate: null,
                          },
                          widthPx: 110,
                        },
                        {
                          name: 'groupId',
                          label: 'Viewing Single Group',
                          defaultValue: '',
                          type: FilterType.CHIP,
                        },
                        {
                          name: 'cardId',
                          label: 'Viewing Single Card',
                          defaultValue: '',
                          type: FilterType.CHIP,
                        },
                      ]}
                    />
                  </Box>
                  <Table<TransactionAuthCombinedCleaned>
                    data={paginationData}
                    pagination={pagination}
                    columns={columns}
                    isLoading={isLoading}
                    combinedFirstColumn={{
                      primaryColumnKey: 'prettyDisplayDate',
                      columnComparatorKey: 'isDisplayDateSameAsPrev',
                      alwaysShowTimestamp: true,
                      timestampKey: 'displayDate',
                    }}
                    onRowClick={({ stripeId, type }) => {
                      openTransactionModal(stripeId, type);
                    }}
                    columnWidths={columns
                      .map((column) => column.width)
                      .join(' ')}
                    css={css`
                      margin-top: 10px;
                      width: 100%;
                    `}
                  />
                  <TableLoader isLoading={isLoading} />
                  <TableError isLoading={isLoading} isError={isError} />
                  <TableNoResults
                    isLoading={isLoading}
                    resultCount={resultCount}
                    searchTerms={searchProps.searchValue}
                    noResultMessage={`Please try a different search term. You can search by the merchant name, the cardholder${`'`}s name or the card's last four digits.`}
                  />
                  <TablePagination
                    resultCount={resultCount}
                    pageCount={pageCount}
                    pagination={pagination}
                  />
                </>
              );
            }}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <CreateDisputeModal />
      <TransactionDetailsModal anchorEl={cardRef.current} />
    </>
  );
};

export default Transactions;
