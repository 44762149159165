import { omit } from 'lodash';

import { formatDateToMST } from 'src/utils';

import type { SubUserCleaned, SubUserRaw } from 'src/types/user';

export const cleanSubUsers = (rawSubUsers: SubUserRaw[]) =>
  rawSubUsers.reduce((acc, balanceTransfer) => {
    const prevSubuser = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(balanceTransfer.created_at),
    );
    const status = (balanceTransfer.status ?? '').toLowerCase();
    return [
      ...acc,
      {
        id: balanceTransfer.id,
        name: {
          first: balanceTransfer.first_name,
          last: balanceTransfer.last_name,
          full: [balanceTransfer.first_name, balanceTransfer.last_name].join(
            ' ',
          ),
        },
        email: balanceTransfer.email,
        status,
        isActive: status === 'active',
        createdAt: balanceTransfer.created_at,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevSubuser?.prettyCreatedAtDate === prettyCreatedAtDate,
        privileges: omit(balanceTransfer.privileges, ['id', 'user_id']),
      } as SubUserCleaned,
    ];
  }, [] as SubUserCleaned[]);
