import { css } from '@emotion/react';
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  NavigateBeforeOutlined as NavigateBeforeOutlinedIcon,
  NavigateNextOutlined as NavigateNextOutlinedIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import {
  Row,
  Cell,
  Table as TableBase,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
} from '@table-library/react-table-library';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useTheme } from '@table-library/react-table-library/theme';
import { merge } from 'lodash';
import { Fragment } from 'react';

import { COLOR_PALETTE } from 'src/theme';
import { formatDateToMST } from 'src/utils';

import { NoResultsIcon } from '../Icon/NoResultsIcon';

import type { Interpolation, Theme as EmotionTheme } from '@emotion/react';
import type { Data, TableNode } from '@table-library/react-table-library';
import type { Column } from '@table-library/react-table-library/compact';
import type { Theme } from '@table-library/react-table-library/theme';
import type { Pagination } from '@table-library/react-table-library/types/pagination';

export function RowWithMergedFristColumn<TableData extends TableNode>({
  rowData,
  columnCount,
  primaryColumnKey,
  columnComparatorKey,
}: {
  rowData: TableData;
  columnCount: number;
  primaryColumnKey: keyof TableData;
  columnComparatorKey: keyof TableData;
}) {
  if (rowData[columnComparatorKey]) {
    return null;
  } else {
    const cssStyles = css`
      position: sticky;
      top: ${40}px;
      background-color: #f6f6f6 !important;
      font-size: 14px;
      height: 24px;
      border-bottom: none;
      color: #141414;
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        border-top: 1px solid #f6f6f6;
      }
    `;
    const spacers = new Array(columnCount - 1).fill(null);
    return (
      <Row key={rowData.id} item={rowData}>
        <Cell css={cssStyles}>{rowData[primaryColumnKey]}</Cell>
        {spacers.map((_, index) => {
          return <Cell css={cssStyles} key={index} />;
        })}
      </Row>
    );
  }
}

export type TableColumn<TableData extends TableNode> = Column<TableData> & {
  renderLabel?: () => JSX.Element;
  headerCss?: Interpolation<EmotionTheme>;
};

export function Table<TableData extends TableNode>({
  data,
  pagination,
  columns,
  isLoading,
  onRowClick,
  combinedFirstColumn,
  columnWidths,
  theme: themeProp,
  className,
}: {
  data: Data<TableData>;
  pagination: Pagination<TableData>;
  columns: TableColumn<TableData>[];
  isLoading: boolean;
  onRowClick?: (row: TableData) => void;
  combinedFirstColumn?: {
    primaryColumnKey: keyof TableData;
    columnComparatorKey: keyof TableData;
    alwaysShowTimestamp?: boolean;
    timestampKey?: keyof TableData;
  };
  columnWidths?: string;
  theme?: Theme;
  className?: string;
}) {
  const tableTheme = merge(
    {},
    getTheme(),
    {
      Table: `
        position: relative;
        --data-table-library_grid-template-columns: ${
          columnWidths ?? '1fr'
        } !important;
        height: initial;
        margin: 0;
    `,
      HeaderCell: `
        background-color: #FFF!important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: ${COLOR_PALETTE.textOnLight};
        border-bottom: 2px solid ${COLOR_PALETTE.tableRowOddHighlight};
        text-transform: uppercase;
        &:first-of-type {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 25px;
        }
        `,
      Row: `
          &:nth-of-type(odd) {
            background-color: ${COLOR_PALETTE.tableRowOddHighlight};
          }
            &:hover .td {

            background-color: ${COLOR_PALETTE.tableRowHover}! important;
            }
        `,
      Cell: `
        color: ${COLOR_PALETTE.textOnLight};
        border-bottom: 1px solid ${COLOR_PALETTE.tableRowOddHighlight}!important;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 10px 14px;
        &:first-of-type {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 25px;
        }
    `,
    } as Theme,
    themeProp,
  );
  const theme = useTheme(tableTheme);

  return (
    <TableBase
      data={data}
      theme={theme}
      layout={{
        horizontalScroll: true,
        fixedHeader: true,
      }}
      pagination={pagination}
      className={className}
    >
      {(tableList: TableData[]) => (
        <>
          <Header>
            <HeaderRow>
              {columns.map((column, index) => (
                <HeaderCell key={column.label ?? index} css={column.headerCss}>
                  {column.renderLabel ? column.renderLabel() : column.label}
                </HeaderCell>
              ))}
            </HeaderRow>
          </Header>
          {!isLoading && (
            <Body>
              {tableList.map((row) => (
                <Fragment key={row.id}>
                  <Row
                    item={row}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row);
                      }
                    }}
                    css={(theme) => css`
                      color: ${theme.palette.text.secondary};
                      ${onRowClick
                        ? css`
                            &:hover {
                              &,
                              & > * {
                                color: ${theme.palette.text.primary};
                                background-color: rgba(0, 0, 0, 0.02);
                              }
                            }

                            &,
                            * {
                              cursor: pointer;
                            }
                          `
                        : ''}
                    `}
                  >
                    {columns.map((column, index) => {
                      if (index === 0 && combinedFirstColumn) {
                        if (row[combinedFirstColumn.columnComparatorKey]) {
                          return (
                            <Cell key={column.label}>
                              <Box
                                css={css`
                                  display: flex;
                                  width: 100%;
                                `}
                              >
                                <Box
                                  component="span"
                                  css={css`
                                    display: block;
                                    min-width: 86px;
                                  `}
                                />
                                <Box
                                  component="span"
                                  css={css`
                                    display: block;
                                  `}
                                >
                                  {combinedFirstColumn.alwaysShowTimestamp &&
                                    !!combinedFirstColumn.timestampKey &&
                                    !!row &&
                                    formatDateToMST(
                                      row[combinedFirstColumn.timestampKey],
                                      'h:mmaaa',
                                    )}
                                </Box>
                              </Box>
                            </Cell>
                          );
                        } else {
                          return (
                            <Cell key={column.label}>
                              <Box
                                css={css`
                                  display: flex;
                                  width: 100%;
                                `}
                              >
                                <Box
                                  component="span"
                                  css={css`
                                    display: block;
                                    min-width: 86px;
                                  `}
                                >
                                  {row[combinedFirstColumn.primaryColumnKey]}
                                </Box>
                                <Box
                                  component="span"
                                  css={css`
                                    display: block;
                                  `}
                                >
                                  {combinedFirstColumn.alwaysShowTimestamp &&
                                    !!combinedFirstColumn.timestampKey &&
                                    !!row &&
                                    formatDateToMST(
                                      row[combinedFirstColumn.timestampKey],
                                      'h:mmaaa',
                                    )}
                                </Box>
                              </Box>
                            </Cell>
                          );
                        }
                      } else
                        return (
                          <Cell key={column.label}>
                            {column.renderCell(row)}
                          </Cell>
                        );
                    })}
                  </Row>
                </Fragment>
              ))}
            </Body>
          )}
        </>
      )}
    </TableBase>
  );
}

export const TableLoader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Box
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 8px;
        width: 100%;
        flex: 1 1 auto;
      `}
    >
      <CircularProgress size={60} />
    </Box>
  );
};
export const TableError = ({
  isLoading,
  isError,
}: {
  isLoading: boolean;
  isError: boolean;
}) => {
  if (isLoading || !isError) {
    return null;
  }
  return (
    <Box
      css={css`
        width: 100%;
      `}
    >
      {/* TODO: style this */}
      Something went wrong, please refresh the page or try again later.
    </Box>
  );
};
export const TableNoResults = ({
  isLoading,
  resultCount,
  searchTerms,
  noResultMessage = 'Please try a different search term.',
  className,
}: {
  isLoading: boolean;
  resultCount?: number;
  searchTerms?: string;
  noResultMessage?: string;
  className?: string;
}) => {
  if (isLoading) {
    return null;
  }
  if (!resultCount) {
    return (
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 8px;
          width: 100%;
          height: 100%;
        `}
        className={className}
      >
        <NoResultsIcon
          css={css`
            width: 60px;
            height: 60px;
          `}
        />
        {searchTerms && (
          <Typography
            css={css`
              font-weight: 600;
            `}
          >
            No results found for {`"${searchTerms}"`}
          </Typography>
        )}
        <Typography
          css={css`
            color: ${COLOR_PALETTE.lightTextOnDark};
          `}
        >
          {noResultMessage}
        </Typography>
      </Box>
    );
  }
  return null;
};
export function TablePagination<Data extends TableNode>({
  resultCount,
  pageCount,
  pagination,
  className,
  noSidePadding,
  scrollRef,
}: {
  resultCount?: number;
  pageCount?: number;
  pagination: Pagination<Data>;
  className?: string;
  noSidePadding?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) {
  if (!resultCount) {
    return null;
  }
  return (
    <Box
      css={css`
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
        padding: ${noSidePadding ? '5px 0' : '5px 16px'};
        position: relative;

        .MuiSvgIcon-root {
          color: ${COLOR_PALETTE.textOnLight};
        }
      `}
      className={className}
    >
      <Box
        css={css`
          content: '';
          position: absolute;
          top: 0;
          left: ${noSidePadding ? '0' : '16px'};
          right: ${noSidePadding ? '0' : '16px'};
          border-top: 1px solid ${COLOR_PALETTE.cardBorder};
        `}
      />
      <Box
        css={css`
          margin-right: auto;
        `}
      >
        <Typography
          variant="body2"
          css={css`
            color: ${COLOR_PALETTE.lightTextOnDark};
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          `}
        >
          <Box
            component="span"
            css={css`
              font-weight: 400;
            `}
          >
            {resultCount ?? '?'}
          </Box>{' '}
          result
          {resultCount === 1 ? '' : 's'}
        </Typography>
      </Box>
      {pagination.state.page > 2 && (
        <IconButton
          disabled={pagination.state.page === 1}
          onClick={() => {
            if (scrollRef?.current) {
              scrollRef.current.scrollTop = 0;
            }
            pagination.fns.onSetPage(1);
          }}
          css={css`
            padding: 4px;
          `}
        >
          <FirstPageOutlinedIcon
            css={css`
              height: 20px;
              width: 20px;
            `}
          />
        </IconButton>
      )}
      {pagination.state.page > 1 && (
        <IconButton
          disabled={pagination.state.page === 1}
          onClick={() => {
            if (scrollRef?.current) {
              setTimeout(() => {
                if (scrollRef?.current) {
                  scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                }
              }, 10);
            }
            pagination.fns.onSetPage(pagination.state.page - 1);
          }}
          css={css`
            padding: 4px;
          `}
        >
          <NavigateBeforeOutlinedIcon
            css={css`
              height: 20px;
              width: 20px;
            `}
          />
        </IconButton>
      )}
      <Typography
        css={css`
          color: ${COLOR_PALETTE.textOnLight};
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        `}
      >
        PAGE {pagination.state.page} {`/`} {pageCount ?? '?'}
      </Typography>
      <IconButton
        disabled={pagination.state.page === pageCount}
        css={css`
          opacity: ${pagination.state.page === pageCount || !pageCount ? 0 : 1};
          padding: 4px;
        `}
        onClick={() => {
          if (scrollRef?.current) {
            scrollRef.current.scrollTop = 0;
          }
          pagination.fns.onSetPage(pagination.state.page + 1);
        }}
      >
        <NavigateNextOutlinedIcon
          css={css`
            height: 20px;
            width: 20px;
          `}
        />
      </IconButton>
    </Box>
  );
}
