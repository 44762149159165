import { useGetFeatures } from 'src/hooks';

export enum Privilege {
  transactions = 'transactions',
  cards = 'cards',
  card_details = 'card_details',
  issue_card = 'issue_card',
  send_card = 'send_card',
  balance = 'balance',
  fund_balance = 'fund_balance',
  linked_accounts = 'linked_accounts',
  dispute = 'dispute',
  create_dispute = 'create_dispute',
  subuser_admin = 'subuser_admin',
  company_details = 'company_details',
  create_edit_groups = 'create_edit_groups',
}

export type UserPrivileges = Record<Privilege, boolean>;

const userPrivilegesLabel: Record<Privilege, string> = {
  transactions: 'View Transactions',
  cards: 'View Groups/Cardholders/Cards',
  card_details: 'Edit Cardholders/Cards',
  issue_card: 'Create Cardholders/Cards',
  send_card: 'Send Cards',
  balance: 'View Balance',
  fund_balance: 'Fund Balance',
  linked_accounts: 'Link Accounts',
  dispute: 'View Disputes',
  create_dispute: 'Create Disputes',
  subuser_admin: 'Manage Users',
  company_details: 'Edit Company Details',
  create_edit_groups: 'Create and Edit Groups',
};

export const useGetUserPrivilegesLabels = () => {
  const { features } = useGetFeatures();
  return {
    userPrivilegesLabel: {
      ...userPrivilegesLabel,
      ...(features?.groups ? {} : { cards: 'View Cardholders/Cards' }),
    },
  };
};

export interface SubUserRaw {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  account_number: string;
  admin_privilege: boolean;
  status: string;
  stripe_cardholder_id: string | null;
  created_at: string;
  privileges: {
    id: string;
    user_id: string;
    transactions: boolean;
    cards: boolean;
    card_details: boolean;
    issue_card: boolean;
    send_card: boolean;
    balance: boolean;
    fund_balance: boolean;
    linked_accounts: boolean;
    dispute: boolean;
    create_dispute: boolean;
    subuser_admin: boolean;
    company_details: boolean;
    create_edit_groups: boolean;
  };
}

export interface SubUserCleaned {
  id: SubUserRaw['id'];
  name: {
    first: SubUserRaw['first_name'];
    last: SubUserRaw['last_name'];
    full: string;
  };
  email: SubUserRaw['email'];
  status: SubUserRaw['status'];
  isActive: boolean;
  createdAt: SubUserRaw['created_at'];
  prettyCreatedAtDate: string;
  isCreatedAtDateSameAsPrev: boolean;
  privileges: UserPrivileges;
}

export interface SubUserDetailsRaw {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  account_number: string;
  admin_privilege: boolean;
  status: string;
  stripe_cardholder_id: string | null;
  created_at: string;
  privileges: {
    id: string;
    user_id: string;
    transactions: boolean;
    cards: boolean;
    card_details: boolean;
    issue_card: boolean;
    send_card: boolean;
    balance: boolean;
    fund_balance: boolean;
    linked_accounts: boolean;
    dispute: boolean;
    create_dispute: boolean;
    subuser_admin: boolean;
    company_details: boolean;
    create_edit_groups: boolean;
  };
}

export interface SubUserDetailsCleaned {
  id: SubUserDetailsRaw['id'];
  name: {
    first: SubUserDetailsRaw['first_name'];
    last: SubUserDetailsRaw['last_name'];
    full: string;
  };
  email: SubUserDetailsRaw['email'];
  status: SubUserDetailsRaw['status'];
  isActive: boolean;
  createdAt: SubUserDetailsRaw['created_at'];
  prettyCreatedAtDate: string;
  privileges: UserPrivileges;
}

export enum UserStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  ONBOARDING_INCOMPLETE = 'onboarding_incomplete',
  PENDING_CLERK_INVITATION = 'pending_clerk_invitation',
}
