import { useMutation } from '@tanstack/react-query';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useAxios } from './useAxios';

interface GenerateContinueStripeRegistrationLinkReturn {
  object: 'account_link';
  created: number; // 1700676009
  expires_at: number; //1700676309
  url: string; // "https://connect.stripe.com/setup/c/acct_1OFKXNQ7GKZWJltc/Cw2igHfghyFP"
}

export const useGenerateContinueStripeRegistrationLink = () => {
  const { axios, generateSecureHeaders } = useAxios();

  const generateContinueStripeRegistrationLink = async () => {
    const { data } =
      await axios.post<GenerateContinueStripeRegistrationLinkReturn>(
        SERVICE_BENJI_CARD_URLS.USER_GENERATE_CONTINUE_STRIP_REGISTRATION_LINK,
        {},
        { headers: await generateSecureHeaders() },
      );
    return data;
  };

  return useMutation({
    mutationFn: () => generateContinueStripeRegistrationLink(),
  });
};
